<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة موظف مبيعات</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createSalesPerson" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> الاسم<span class="red">*</span>
                  <span color='danger' class="error">{{errors.name?errors.name[0]:''}}</span>
                  </label>
                  <vmd-input
                    id="inp"
                    type="text"
                    label="الاسم"
                    v-model='salesPersonN'
                    :name="salesPersonN"
                    required
                    />
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ
                  </vmd-button>
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    type='reset'
                    > مسح
                  </vmd-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'

export default {
  name: 'AddSubject',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      salesPersonN: '',
      errors: []
    }
  },
  created () {
  },
  methods: {
    async createSalesPerson (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.salesPersonN)) {
        swal('اسم الموظف يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.salesPersonN)

      this.errors = await this.postRequest(formData, this.$hostUrl + 'salespeople', '/sales')
    }
  }
}
</script>
